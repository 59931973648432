<template>
    <!-- User Profile Personal Basic Info Tab Content View From Account Profile -->
    <div class="mr-2">
        <div class="d-flex flex-row ml-2 flex-wrap justify-content-between align-items-center">
            <div class="breadcrumbactive">
                <div>
                    <span class="pointer" @click="goToCompanyList">Company</span>
                    <span class="pointer">List View</span>
                    <span class="pointer">View Company</span>
                    <div>Outlet</div>
                </div>
            </div>
            <!-- <div class="d-flex align-items-center">
                <div v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" class="text-right">
                    <button class="btn btn-new-primary" @click="showCreateBranch">+ Add Outlet</button>
                </div>
            </div> -->
        </div>
        <div class="tabs-content-secondblock pb-20 mb-10">
            <div class="row">
                <div class="col-lg-12">
                    <div class="ml-3 mr-1 mb-3">
                        <div class="row" v-if="ajax_call_in_progress">
                            <div class="col-lg-12 py-5 px-3 mt-3">
                                <div class="flex-container">
                                    <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                </div>
                            </div>
                        </div>
                        <div v-else class="row">
                            <div class="col-lg-12">
                                <div class="infoHeader" style="background-color: #fff;border-radius: 8px;">
                                    <div class="d-flex justify-content-between align-items-center basic-info" style="background-color: #00448b;border-radius: 0px 6px 0 0;">
                                        <h6 class="subHeaderTitle my-2 text-white pl-5">
                                            <span class="pl-2 fs-14 fw-500 profile-text">Outlets</span>
                                        </h6>
                                        <div v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" style="margin-right: 10px;">
                                            <button type="button" @click="showCreateBranch" class="btn btn-add-outlet">+ Add Outlet</button>
                                        </div>
                                    </div>
                                    <div class="pt-3 pl-2 px-6">
                                        <div v-if="outlet_data.length && !ajax_call_in_progress" class="row flex-wrap align-items-center pb-10">
                                            <div v-for="(outlet, outlet_index) in outlet_data" :key="outlet_index" class="col-lg-4 col-md-4 col-sm-6 pt-5">
                                                <div class="new-card" >
                                                    <div class="d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <h1 class="fs-14 my-2 ml-2" style="color: #3f3f3f;border-bottom: 0.5px solid #707070;">Outlet {{outlet_index+1}}</h1>
                                                        </div>
                                                        <div class="position-relative justify-content-end">
                                                        <!-- <div  class="position-relative justify-content-end"> -->
                                                            <i v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" @click="goToOutletView(outlet.id)" class="icon icon-pencil defaultDarkBlue pointer fs-20"></i>
                                                            <!-- <i @click="goToOutletView(outlet.id)" class="icon icon-pencil text-secondary pointer fs-18 mr-2"></i> -->
                                                            <i v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" @click="showDeleteConfirm(outlet.id)" class="icon icon-delete defaultDarkBlue pointer fs-20"></i>
                                                        </div>
                                                    </div>
                                                    <div class="pointer pt-2">
                                                        
                                                        <div class="d-flex align-items-center justify-content-between pl-2 pr-3">
                                                            <h2 class="fs-14 text-secondary fw-500 text-break pr-2">Outlet Name</h2>
                                                            <h2 class="fs-14 fw-500 text-capitalize text-data pl-2">{{outlet.outlet_name || "-"}}</h2>
                                                        </div>
                                                        <div class="d-flex align-items-center justify-content-between pl-2 pr-3">
                                                            <h2 class="fs-14 text-secondary fw-500 text-break pr-2">Outlet Type</h2>
                                                            <h2 class="fs-14 fw-500 text-capitalize text-data pl-2">{{outlet.outlet_type || "-"}}</h2>
                                                        </div>
                                                        <div class="mt-2 pl-2 pr-3">
                                                            <div class="text-secondary d-flex align-items-center fs-14 fw-400">
                                                                <span class="mr-2" style="transform: rotate(270deg);display: inline-block;"><i class="icon icon-phone defaultDarkBlue fs-18"></i></span>
                                                                {{outlet.phone_number || "-"}}
                                                            </div>
                                                            <div class="text-secondary d-flex align-items-center fs-14 fw-400 mt-1">
                                                                <i class="icon icon-email defaultDarkBlue fs-18"></i>
                                                                <span style="text-decoration: underline;">{{outlet.email || "-"}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class="row flex-wrap align-items-center">
                                            <div class="col-lg-12 col-md-12 col-sm-12">
                                                <div class="text-center my-10">
                                                    <img src="/static/images/outlet-illustrator.svg" width="400" />   
                                                </div>
                                                <p class="text-secondary fs-14 text-center">Add outlet here!</p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div v-if="outlet_data.length && !ajax_call_in_progress" class="d-flex justify-content-end align-items-center bg-white py-2 px-4 pb-10" style="border-bottom-left-radius: 5px; border-bottom-right-radius: 5px">
                                    <multiselect class="diginew-multiselect"
                                        :options="pageLimit" :close-on-select="true" v-model.number="limit"
                                        open-direction="top" :show-labels="false" placeholder=""
                                        style="width: 3.6em !important;padding-right: 10px!important;padding-top: 10px !important;"></multiselect>
                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/down-arrow-grey.svg" style="margin-left: -20px;">
                                    <span class="page-info pr-2 pl-6 fw-500" style="height: 2.4rem;line-height: 2.4rem;color: #303031;font-size: 14px;">Per page</span>
                                    <button @click="firstPage()"  :class="{'not-allowed' : skip == 0}" :disabled="skip == 0" class="mt-1 pointer pagination-list">
                                        <el-tooltip class="item" effect="dark" content="First Page" placement="top">
                                            <i class="icon-chevron-double-left"></i>
                                        </el-tooltip>    
                                    </button>
                                    <button @click="previousPage()"  :class="{'not-allowed' : skip == 0}" :disabled="skip == 0" class="pagination-list mt-1 ml-2 pointer">
                                        <el-tooltip class="item" effect="dark" content="Previous Page" placement="top">
                                            <i class="icon icon-chevron-left"></i>
                                        </el-tooltip>    
                                    </button>
                                    <span class="px-2 fw-500 fs-14 mt-1">Showing {{getSkipCount}} - {{total>skip+limit?skip+limit:total}} of {{total}}</span>
                                    <button @click="nextPage()" :class="{'not-allowed' : temp_total == total }" :disabled="temp_total == total" class="pagination-list mt-1 mr-2 pointer">
                                        <el-tooltip class="item" effect="dark" content="Next Page" placement="top">
                                            <i class="icon icon-chevron-right"></i>
                                        </el-tooltip>    
                                    </button>
                                    <button @click="lastPage()" :class="{'not-allowed' : temp_total == total}" class="mt-1 pointer pagination-list" :disabled ="temp_total == total">
                                        <el-tooltip class="item" effect="dark" content="Last Page" placement="top">
                                            <i class="icon-chevron-double-right"></i>
                                        </el-tooltip>    
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <outlet-delete-popup v-if="delete_confirm" @hideConfirm="hideConfirm" @deleteConfirm="deleteConfirm" modal_name="delete_confirm" ></outlet-delete-popup>
        <create-outlets v-if="create_outlet" @hideCreateOutlet="hideCreateOutlet" @hideCreateOutletSuccess="hideCreateOutletSuccess" modal_name="create-outlet"></create-outlets>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
    </div>
</template>
<script>
    import moment from "moment";
    import {
        HalfCircleSpinner
    } from "epic-spinners";
    const CreateOutlets = () => import('./Outlets/CreateOutlets');
    import {
        EventBus
    } from "../eventBus/nav-bar-event.js";
    import { SweetModal } from 'sweet-modal-vue';
    import companies from '../mixins/companies.js';
    import OutletDeletePopup from './OutletDeletePopup.vue';
    export default {
        props: [],
        components:{
            CreateOutlets,
            HalfCircleSpinner,
            SweetModal,
            OutletDeletePopup,
        },
        data() {
            return {
                create_outlet: false,
                ajax_call_in_progress: false,
                outlet_data: [],
                warning_msg: '',
                success_msg: '',
                delete_confirm: false,
                outlet_delete: '',
                total: 0,
                skip_temp:0,
                temp_total:0,
                skip:0,
                limit: 10,
                pageLimit: [],
            };
        },
        mixins: [companies],
        methods: {
            goToCompanyList() {
                this.$router.push('/company/view/cmpid/' + this.$route.params.company_id);
            },
            previousPage() {
                this.temp_total = 0
                this.skip_temp = 0
                if (this.skip == 0) {
                    return;
                }
                this.skip = this.skip - this.limit <= 0 ? 0 : this.skip - this.limit;
                this.getListViewData();
            },
            nextPage() {
                this.skip_temp = this.skip + this.limit > this.total ? this.total : this.skip + this.limit;
                if (this.skip_temp >= this.total) {
                    return;
                }
                this.skip = this.skip_temp;
                this.getListViewData();
            },
            firstPage(){
                this.temp_total = 0
                this.skip = 0
                this.limit = this.limit
                this.list_view_data = [] 
                this.getListViewData();
            },
            lastPage() {
                this.skip_temp = 0
                this.skip = 0
                this.temp_total = this.total
                let no_of_pages = Math.ceil(this.total/this.limit)
                this.skip_temp = (no_of_pages * this.limit) - this.limit
                if (this.skip_temp >= this.total) {
                    return;
                }
                this.skip = this.skip_temp
                this.getListViewData();
            },
            hideConfirm() {
                this.delete_confirm = false;
            },
            showDeleteConfirm(id) {
                this.outlet_delete = id
                this.delete_confirm = true;
                setTimeout(() => {
                    this.$modal.show('delete_confirm');
                }, 500);
            },
            deleteConfirm() {
                // this.DeleteAll();
                if(this.outlet_delete === undefined || this.outlet_delete === null || this.outlet_delete === '') {
                    this.delete_confirm = false;
                } else {
                    this.deleteOutlet(this.outlet_delete);
                }
            },
            moment(date) {
                return moment(date);
            },
            hideCreateOutlet() {
                this.create_outlet = false;
                // this.getListViewData();
            },
            hideCreateOutletSuccess(){
                this.create_outlet = false;
                this.getListViewData();
            },
            showCreateBranch() {
                this.create_outlet = true;
                setTimeout(() => {
                    this.$modal.show('create-outlet');
                }, 500);
            },
            async getListViewData() {
                this.ajax_call_in_progress = true;
                try {
                    let params = {
                        skip: this.skip,
                        limit: this.limit == "" || this.limit == 0 || this.limit < 0 ? 10 : this.limit,
                        company_id: this.$route.params.company_id
                    };
                    let response = await this.getListOutlets(params);
                    if(response.status_id == 1) {
                        this.outlet_data = response.response
                        this.total = response.total_count;
                    }
                    this.ajax_call_in_progress = false;
                }
                catch(err) {
                    console.log(err);
                }
            },
            async getCompanyInfo() {
                try {
                    let response = await this.getCompanyById(this.$route.params.company_id);
                    if(response.status_id == 1) {
                        localStorage.setItem('company_outlet_domain_name', response.response.domain);
                    }
                }
                catch(err) {
                    // console.log(err);
                }
            },
            async deleteOutlet(id) {
                // this.$swal({
                //     title: 'Alert',
                //     text: "Are you sure want to delete ?",
                //     type: 'warning',
                //     icon: 'warning',
                //     showCloseButton: true,
                //     showCancelButton: true,
                //     confirmButtonText:'YES',
                //     cancelButtonText:'NO'
                // }).then(async (result) => {
                //     if (result.value) {
                        try {
                            let response = await this.deleteOutletsById(id);
                            console.log(response);
                            if(response.status_id == 1) {
                                this.success_msg = response.message
                                this.$refs.success_modal.open();
                                setTimeout(() => {
                                    this.$refs.success_modal.close();
                                    this.getListViewData();
                                }, 2000);
                            }
                        }
                        catch(err) {
                            // console.log(err);
                            this.warning_msg = err;
                            this.$refs.warning_modal.open();
                        }
                //     }
                // })
            },
            goToOutletView(id) {
                this.$router.push({ path: "/company/outlet/view/cmpid/"+this.$route.params.company_id+'/'+id })
            }
        },
        props: [],
        mounted() {
            this.getListViewData();
            this.getCompanyInfo();
        },
        created() {
            for (let i = 10; i <= 50; i+=10) {
                this.pageLimit.push(i);
            }
        },
        watch: {
            limit: function(){
                this.getListViewData()
            },
            // "edit_user_data.gender": function(newVal,oldVal) {
            //     if (newVal == 'Custom') {
            //         this.custom_gender_active = true;
            //     } else {
            //         this.custom_gender_active = false;
            //     }
            // },
        },
        computed: {
            getSkipCount(){
                if(this.total == 0) {
                    return 0
                } else {
                    let skip_count = this.skip>this.total?this.total:this.skip+1;
                    return skip_count;
                }
            },
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
            // account() {
            //     return this.$store.getters.account;
            // },
            // selectedCompany() {
            //     return this.$store.getters.selectedCompany;
            // },
        },
    };
</script>
<style scoped>
    .new-card {
        padding: 3.5px 5.5px 12.8px 11.5px;
        border-radius: 2.5px;
        box-shadow: 0 0 10px 0 #e5e5e5;
        background-color: var(--white);
    }
    .btn-new-primary {
        color: #fff;
        border-radius: 5px;
        padding-left: 25px;
        padding-right: 25px;
    }
    .btn-add-outlet {
        color: #058125;
        border: 1px solid #058125;
        background-color: #fff;
        padding: 5px 10px !important;
        line-height: 1;
        font-weight: 500;
        height: auto;
    }
    .not-allowed{
        cursor: no-drop !important;
    }
</style>